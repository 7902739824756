import React from "react";
import { styled } from "goober";

import { appURLs } from "@/app/app-urls.mjs";
import ArticleChanges from "@/game-eft/articles/changes.mdx";
import definition from "@/game-eft/definition.mjs";
import GameArticle, { ArticleCard } from "@/shared/GameArticle.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const PATCH_TO_PATCH_NOTES_MAP = {
  "since-big-bang": {
    metaTitle: [
      "eft:meta.patchNotes.01411.title",
      "Escape From Tarkov Patch Notes 0.14.1.1",
    ],
    title: definition.fullName,
    subTitle: [
      "eft:meta.patchNotes.01411.title",
      "Escape From Tarkov Patch Notes 0.14.1.1",
    ],
    description: [
      "eft:meta.patchNotes.01411.title",
      "Escape From Tarkov Patch Notes 0.14.1.1",
    ],
    content: ArticleChanges,
    date: new Date("March 24, 2024 10:00:00"),
    imageURL: `${appURLs.CDN}/eft/articles/eft-splash.webp`,
  },
};

const Card = styled(ArticleCard)`
  iframe {
    aspect-ratio: 16 / 9;
    border-radius: var(--br-lg);
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--sp-5);
    max-width: 100%;
    width: 550px;
  }

  a {
    color: var(--primary);
    text-decoration: underline;
  }
`;

function PatchNotes() {
  const { parameters } = useRoute();
  const patch = parameters[0];
  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return (
    <GameArticle
      articleHeader={{
        title: content.title,
        subTitle: content.subTitle,
        date: content.date,
        imageURL: content.imageURL,
      }}
      ContentMDX={content.content}
      components={{ Card }}
    />
  );
}

export default PatchNotes;

export const meta = (parameters) => {
  const patch = parameters[0];

  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return {
    title: content.metaTitle,
    description: content.description,
    subtitle: true,
    image: {
      url: content.imageURL,
      alt: content.title,
      width: 656,
      height: 369,
    },
  };
};
