import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h3: "h3",
    li: "li",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Card} = _components;
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "list-of-changes",
      children: _jsx(_components.a, {
        href: "#list-of-changes",
        children: "List of changes"
      })
    }), "\n", _jsx(Card, {
      children: _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Reworked the damage through layers of armor: now when each layer is penetrated, the bullet loses some penetration power and damage depending on the characteristics of the penetrated armor"
        }), "\n", _jsx(_components.li, {
          children: "Fixed an issue with missing blunt damage when hitting a ballistic plate"
        }), "\n", _jsx(_components.li, {
          children: "Blunt damage now decreases when there is soft armor behind the unpenetrated component"
        }), "\n", _jsx(_components.li, {
          children: "Adjusted the head colliders (eyes, ears)"
        }), "\n", _jsx(_components.li, {
          children: "Removed the possibility for bullets to pierce through a character's thorax and stomach"
        }), "\n", _jsx(_components.li, {
          children: "Added the \"fits existing\" text to the ballistic plate inspector to display which armors the plate fits to"
        }), "\n", _jsx(_components.li, {
          children: "Added the ability to fit Granit Br4 and Br5 plates into WARTECH TV-110 and TV-115 plate carriers"
        }), "\n", _jsx(_components.li, {
          children: "Added side ballistic plate slots for the S&S Precision PlateFrame (Goons Edition) plate carrier"
        }), "\n", _jsx(_components.li, {
          children: "Added contact shadows for the BTR and updated visual effects of BTR machine gun fire"
        }), "\n", _jsx(_components.li, {
          children: "Adjusted the random container spawn settings to reduce the chance of spawning an empty container"
        }), "\n", _jsx(_components.li, {
          children: "Added the tenths of percentage display when viewing the total number of players who have completed an achievement"
        }), "\n", _jsx(_components.li, {
          children: "Adjusted the sound positioning system on Ground Zero and Streets of Tarkov"
        }), "\n"]
      })
    }), "\n", _jsx(_components.h3, {
      id: "list-of-fixes",
      children: _jsx(_components.a, {
        href: "#list-of-fixes",
        children: "List of fixes"
      })
    }), "\n", _jsx(Card, {
      children: _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Reworked the damage through layers of armor: now when each layer is penetrated, the bullet loses some penetration power and damage depending on the characteristics of the penetrated armor"
        }), "\n", _jsx(_components.li, {
          children: "Fixed an issue with missing blunt damage when hitting a ballistic plate"
        }), "\n", _jsx(_components.li, {
          children: "Blunt damage now decreases when there is soft armor behind the unpenetrated component"
        }), "\n", _jsx(_components.li, {
          children: "Adjusted the head colliders (eyes, ears)"
        }), "\n", _jsx(_components.li, {
          children: "Removed the possibility for bullets to pierce through a character's thorax and stomach"
        }), "\n", _jsx(_components.li, {
          children: "Added the \"fits existing\" text to the ballistic plate inspector to display which armors the plate fits to"
        }), "\n", _jsx(_components.li, {
          children: "Added the ability to fit Granit Br4 and Br5 plates into WARTECH TV-110 and TV-115 plate carriers"
        }), "\n", _jsx(_components.li, {
          children: "Added side ballistic plate slots for the S&S Precision PlateFrame (Goons Edition) plate carrier"
        }), "\n", _jsx(_components.li, {
          children: "Added contact shadows for the BTR and updated visual effects of BTR machine gun fire"
        }), "\n", _jsx(_components.li, {
          children: "Adjusted the random container spawn settings to reduce the chance of spawning an empty container"
        }), "\n", _jsx(_components.li, {
          children: "Added the tenths of percentage display when viewing the total number of players who have completed an achievement"
        }), "\n", _jsx(_components.li, {
          children: "Adjusted the sound positioning system on Ground Zero and Streets of Tarkov"
        }), "\n"]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
